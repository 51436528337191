<template>
  <div class="about">
    <img class="a_Image" src="@/assets/img/about_banner.png" mode="widthFix" />
    <ContentTop></ContentTop>
    <div class="h_content">
      <h3 style="font-size: 2.5rem;margin-top: 2rem;text-align: center;">米悦朝鲜族拌饭荣获饿了么星光奖 全力赋能商家增长</h3>
      <div class="hc_title">
        2025年1月10日，主题为“聚力共赢，数智未来”的饿了么餐饮品牌经营者城市沙龙会议在北京隆重举行。会议旨在探讨餐饮业的数字化转型新趋势，就赋能商家增长、数智经营的新策略进行了深入分析。作为拌饭领域的新锐之星，米悦荣获饿了么2024星光奖。
      </div>
      <img src="@/assets/img/Headlines_2.jpg" mode="widthFix">
      <div class="hc_title">
        米悦创始人黄庆飞现场表示：“这份荣誉不仅标志着米悦在餐饮行业中的卓越地位，也是对每一位加盟商和员工努力的肯定。未来，米悦将持续扎根智能化领域，为广大消费者打造更具创意、更为多元的餐饮生态。” 这一番话不仅表达了他对团队的感激之情，更体现了米悦品牌未来不断追求卓越的坚定信念。
      </div>
      <img style="width: 50rem;margin: 0 auto;display: block;" src="@/assets/img/Headlines_1.jpg" mode="widthFix">
      <div class="hc_title">
        米悦品牌自创立以来，一直秉承着“不计成本·坚持品质”的理念。在产品品质上，米悦严格把控原材料的选择和加工过程，力争确保每一道菜品都能达到消费者的高标准要求。在数字化方面，米悦积极借助饿了么平台，通过大数据分析消费者行为，优化菜单设计，提升顾客体验。此外，米悦借助饿了么平台的庞大流量资源，开展一系列营销活动，例如，饿了么的五折天、品类日、时令大促等平台活动，我们推出经典朝鲜族石锅拌饭产品，吸引了大量消费者参与，给参与活动的加盟商门店带来显著的流量增长；消费者们也能够以更低的价格享受到美味拌饭，这种实惠的消费体验进一步吸引了更多的消费者通过饿了么平台下单，提升了米悦品牌的知名度和市场占有率。
      </div>
      <div class="hc_title">
        米悦创始人黄庆飞在年会上表示：“2024 年米悦门店数量突破 200 家，实现了跨越式增长，覆盖了全国多个城市，成为朝鲜族拌饭品类的代表之一。2025年将持续通过创新研发、运营支持和营销赋能，助力加盟商获得更高效益，共同实现 2025 年 500 家在营门店的突破。”
      </div>
      <div class="hc_title">
        同时，品牌8大业务模块也将围绕这一目标，深入进行市场研究，精准把握市场，助力门店锁定目标客群，制定有效的经营策略；持续加大研发投入，不断推出符合消费者口味需求的新产品；并降低建店成本，提高效率，确保原材料的稳定供应；同时利用大数据智能技术，提升门店的运营效率和顾客体验；为加盟商提供全面的培训和持续的运营支持，确保门店能够顺利运营。这一系列具体且有效的落地性工作，全方位赋能加盟商，助力门店稳健发展。
      </div>
      <div class="hc_title">未来，米悦将继续以数字化为驱动，借助平台的合作，不断迈向更高成就。通过不断创新和优化，米悦致力于构建一个美好的餐饮生态，为消费者带来更加丰富和便捷的用餐体验。</div>
    </div>

    <div class="i_bottom">
      <Bottom :hasScroll="hasScroll"></Bottom>
    </div>

  </div>
</template>

<script>
import Bottom from "@/components/bottom";
import ContentTop from "../../components/content_top.vue";
export default {
  name: "aboutIndex",
  components: {
    ContentTop,
    Bottom
  },
  data() {
    return {
      hasScroll: false
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      this.hasScroll = window.pageYOffset > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.about {
  // .i_bottom {
    // position: absolute;
    // bottom: 0;
  // }
  img {
    width: 100%;
    background: #eeeeee;
  }
  .h_content {
    margin: 0 auto 2.5rem;
    width: 120rem;
    .hc_title {
      font-size: 1.6rem;
      line-height: 3rem;
      font-weight: 400;
      color: #000000;
      margin: 2rem 0;
      text-indent: 3rem;
    }
  }
}
</style>